<template>
  <div>

    <!-- #region::Form validation observer -->
    <validation-observer ref="circuitForm">

      <!-- #region::Form -->
      <b-form @submit="$event.preventDefault()">

        <!-- #region::Card for main options -->
        <div class="mb-2">
          <MainOptions
            :title="id ? 'Actualizar circuito' : 'Nuevo circuito'"
            :save-button-text="id ? 'Guardar cambios' : 'Guardar circuito'"
            model-home-route="circuits-home"
            :callback="saveCircuit"
          />
        </div>
        <!-- #endregion::Card for main options -->

        <!-- #region::Skeleton zone -->
        <template v-if="loadingDataForm">
          <b-skeleton
            class="mt-1"
            animation="wave"
            width="100%"
            height="170px"
          />
        </template>
        <!-- #endregion::Skeleton zone -->

        <template v-else>

          <!-- #region::Circuit information -->
          <b-card>
            <b-card-text>
              <b-form-row>
                <b-col md="12">
                  <h4>Información del circuito</h4>
                </b-col>

                <!-- #region::Circuit number input -->
                <b-col md="2">
                  <TextInputWithValidation
                    v-model="circuitNumber"
                    vid="circuitNumber"
                    type="text"
                    label="No. de circuito"
                    name="no. de circuito"
                    maxlength="200"
                    :disabled="true"
                  />
                </b-col>
                <!-- #endregion::Circuit number input -->

                <!-- #region::Circuit name input -->
                <b-col md="3">
                  <TextInputWithValidation
                    v-model="name"
                    vid="name"
                    rules="required|min:2"
                    type="text"
                    label="Nombre del circuito"
                    name="nombre"
                    maxlength="200"
                    placeholder="Escribe el nombre del circuito"
                  />
                </b-col>
                <!-- #endregion::Circuit name input -->

                <!-- #region::Offer price input -->
                <b-col md="3">
                  <TextInputWithValidation
                    v-model="offerPrice"
                    vid="offerPrice"
                    rules="decimal|max_value:@maxPrice"
                    type="text"
                    label="Precio oferta"
                    name="precio oferta"
                    maxlength="12"
                    placeholder="100.00"
                    prepend="$"
                  />
                </b-col>
                <!-- #endregion::Offer price input -->

                <!-- #region::Minimum price circuit input -->
                <b-col md="2">
                  <TextInputWithValidation
                    v-model="minPrice"
                    vid="minPrice"
                    rules="decimal"
                    type="text"
                    label="Precio mínimo"
                    name="precio mínimo"
                    maxlength="12"
                    placeholder="100.00"
                    prepend="$"
                    :disabled="true"
                  />
                </b-col>
                <!-- #endregion::Minimum price circuit input -->

                <!-- #region::Maximum price circuit input -->
                <b-col md="2">
                  <TextInputWithValidation
                    v-model="maxPrice"
                    vid="maxPrice"
                    rules="decimal"
                    type="text"
                    label="Precio máximo"
                    name="precio máximo"
                    maxlength="12"
                    placeholder="100.00"
                    prepend="$"
                    :disabled="true"
                  />
                </b-col>
                <!-- #endregion::Maximum price circuit input -->

              </b-form-row>
            </b-card-text>
          </b-card>
        <!-- #endregion::Circuit information -->

        </template>

      </b-form>
      <!-- #endregion::Form -->

      <!-- #region::Product tabs -->
      <b-tabs v-if="!loadingDataForm">
        <b-tab title="Todos los productos">
          <b-card-text>
            <ProductsList
              resource="products"
              :show-pieces-list="true"
              :selectable-pieces-list="true"
              :show-add-pieces-buttons="true"
              :can-apply-global-filters="false"
              :can-apply-global-pagination="false"
              @product-list-mounted="onProductListMounted"
            />
          </b-card-text>
        </b-tab>
        <b-tab :title="circuitProductTitle">
          <b-card-text>
            <ProvitionalProductsList
              v-if="productListMounted"
              resource="products"
              :show-pieces-list="true"
              :load-circuit-products="true"
              :show-location-filter="false"
              :selectable-pieces-list="true"
              :can-apply-global-filters="false"
              :can-show-product-metrics="false"
              :show-delete-pieces-buttons="true"
              :can-apply-global-pagination="false"
            />
          </b-card-text>
        </b-tab>
      </b-tabs>
      <!-- #endregion::Product tabs -->

    </validation-observer>
    <!-- #endregion::Form validation observer -->
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  ValidationObserver, localize, extend, decimal,
} from 'vee-validate'
import {
  required, min, minValue, maxValue,
} from '@validations'
import {
  BForm, BCard, BCardText, BCol, BFormRow, BTabs, BTab, BSkeleton,
} from 'bootstrap-vue'

import MainOptions from '@/components/forms/MainOptions.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCol,
    BTab,
    BCard,
    BTabs,
    BForm,
    BFormRow,
    BCardText,
    BSkeleton,
    MainOptions,
    ValidationObserver,
    TextInputWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ProductsList: () => import('@/modules/production/products/components/ProductsList.vue'),
    ProvitionalProductsList: () => import('@/modules/production/products/components/ProductsList.vue'),
  },
  props: {
    number: {
      type: [Number, String],
      default: null,
    },
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      name: '',
      maxPrice: 0,
      minPrice: 0,
      offerPrice: 0,
      loadingDataForm: true,
      circuitNumber: this.id || this.number,
      productListMounted: false,

      // * 05/10/2022 - TAG: Validations
      min,
      decimal,
      required,
      minValue,
      maxValue,
    }
  },
  computed: {
    ...mapGetters({
      getLoadedPieces: 'circuits/getLoadedPieces',
      getCircuitPieces: 'circuits/getCircuitPieces',
      getDeletedPieces: 'circuits/getDeletedPieces',
      getCircuitProducts: 'circuits/getCircuitProducts',
      getDeletedProducts: 'circuits/getDeletedProducts',
    }),
    circuitProducts: {
      get() { return this.getCircuitProducts },
    },
    deletedProducts: {
      get() { return this.getDeletedProducts },
    },
    circuitPieces: {
      get() { return this.getCircuitPieces },
    },
    deletedPieces: {
      get() { return this.getDeletedPieces },
    },
    loadedPieces: {
      get() { return this.getLoadedPieces },
    },
    circuitProductTitle() {
      return `Productos en el circuito (${this.circuitProducts.length})`
    },
    maxCircuitPrice() {
      if (this.circuitProducts.length > 0) {
        const sum = this.circuitProducts.reduce((accumulator, product) => {
          if (product.Type === 'Nuevo' || product.Type === 'Remanufacturado') {
            return accumulator + (product.PriceMax * product.pieces.length)
          }

          return accumulator + (Number(product.MaxSuggestedPrice) * product.pieces.length)
        }, 0)
        return sum
      }

      return 0
    },
    minCircuitPrice() {
      if (this.circuitProducts.length > 0) {
        const sum = this.circuitProducts.reduce((accumulator, product) => {
          if (product.Type === 'Nuevo' || product.Type === 'Remanufacturado') {
            return accumulator + (product.PriceMin * product.pieces.length)
          }

          return accumulator + (product.PriceLf * product.pieces.length)
        }, 0)
        return sum
      }

      return 0
    },
  },
  watch: {
    circuitProducts() {
      this.maxPrice = this.maxCircuitPrice.toFixed(2)
      this.minPrice = this.minCircuitPrice.toFixed(2)
    },
  },
  async created() {
    this.loadingDataForm = true
    const response = await this.loadCircuit(this.id)

    if (response.data.circuits.circuits.length > 0) {
      this.loadExistingCircuit(response.data.circuits.circuits[0])
    }

    localize('es')

    extend('min_value', {
      params: ['target'],
      validate(value, { target }) {
        return Number(value) >= Number(target)
      },
      message: 'El campo {_field_} debe ser mayor o igual a {target}',
    })

    extend('max_value', {
      params: ['target'],
      validate(value, { target }) {
        return Number(value) <= Number(target)
      },
      message: 'El campo {_field_} debe ser menor o igual a {target}',
    })

    this.loadingDataForm = false
  },
  beforeDestroy() {
    this.clearFormData()
  },
  methods: {
    ...mapActions({
      loadCircuit: 'circuits/loadCircuit',
      clearFormData: 'circuits/clearFormData',
      updateCircuit: 'circuits/updateCircuit',
      createCircuit: 'circuits/createCircuit',
      addCircuitProduct: 'circuits/addCircuitProduct',
      addCircuitProductEmptyPieces: 'circuits/addCircuitProductEmptyPieces',
    }),
    async saveCircuit() {
      const success = await this.$refs.circuitForm.validate()

      if (success) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()

        if (this.id) {
          formData.append('id', this.id)
        }

        formData.append('idCircuit', 10)
        formData.append('name', this.name)
        formData.append('priceMax', this.maxPrice)
        formData.append('priceMin', this.minPrice)
        formData.append('offerPrice', this.offerPrice || 0)
        formData.append('qty', this.circuitProducts.length)

        this.circuitProducts.forEach(product => {
          product.pieces.forEach(piece => {
            let pieceData = null

            const isInCircuitList = circuitPiece => circuitPiece.IdPiece === piece.IdPiece
            const inCircuitList = this.circuitPieces.some(isInCircuitList)

            const isInLoadedList = loadedPiece => loadedPiece.IdPiece === piece.IdPiece
            const inLoadedList = this.loadedPieces.some(isInLoadedList)

            if (this.id) {
              if (inCircuitList && !inLoadedList) {
                pieceData = this.buildPieceData(piece, false, true)
              } else {
                pieceData = this.buildPieceData(piece, false, false)
              }
            } else {
              pieceData = this.buildPieceData(piece)
            }

            formData.append('pieces[]', pieceData)
          })
        })

        this.deletedPieces.forEach(piece => {
          const isInCircuitList = circuitPiece => circuitPiece.IdPiece === piece.IdPiece
          const inCircuitList = this.circuitPieces.some(isInCircuitList)

          const isInLoadedList = loadedPiece => loadedPiece.IdPiece === piece.IdPiece
          const inLoadedList = this.loadedPieces.some(isInLoadedList)

          if (!inCircuitList && inLoadedList) {
            const pieceData = this.buildPieceData(piece, true, false)
            formData.append('pieces[]', pieceData)
          }
        })

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        try {
          if (this.id) {
            await this.updateCircuit(formData)
            this.showSwalFire('¡El circuito ha sido actualizado correctamente!')
          } else {
            await this.createCircuit(formData)
            this.showSwalFire('¡El circuito ha sido registrado correctamente!')
          }
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'circuits-home' })
        }
      })
    },
    buildPieceData(piece, mustDelete = false, isNew = false) {
      let pieceData = null

      pieceData = JSON.stringify({
        idProduct: piece.FK_IdProduct,
        idPieza: piece.IdPiece,
        delete: mustDelete,
        new: isNew,
      })

      return pieceData
    },
    loadExistingCircuit(circuit) {
      this.name = circuit.Name
      this.minPrice = circuit.PriceMin
      this.maxPrice = circuit.PriceMax
      this.offerPrice = circuit.OfferPrice

      circuit.products.forEach(product => {
        const pieces = this.buildProductPieces(product.detailCircuit)
        const circuitProduct = { ...product }
        circuitProduct.pieces = pieces.map(piece => ({ ...piece }))
        this.addCircuitProductEmptyPieces(circuitProduct)
      })
    },
    buildProductPieces(details) {
      const pieces = []

      details.forEach(detail => {
        pieces.push(detail.piece)
      })

      return pieces
    },
    onProductListMounted() {
      this.productListMounted = true
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group-disabled {
  background-color: #ededed;
}
</style>
